.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login_text .input-group-text
{
background-color: transparent !important;
border: 1px solid #393939 !important;
}
.airlists .card-body{
  background: unset !important;
}
#sidebar .sidebar_ul
{
  max-height: calc(100vh - 140px);
    overflow-y: auto;
}
.no_data_text
{
  text-align: center;
  color:#fff;
}
.live-btn{
  background: #31d0aa !important;
}
.upcoming-btn{
  background: rgb(244, 169, 71) !important;
}
.end-btn{
  background: red !important;
}
.airtitle{
  color: #fff;
}
.light_theme .airtitle{
  color: black;
  font-weight: 500;
}
.light_theme .startsin{
  font-weight: 500;
}
.airno{
  color: #fff;
}
.light_theme .airno{
  color: black;
  font-weight: 500;
}
.airpodsimg{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid black;
    padding: 6px;
    background-color: black;
}
.light_theme .airpodsimg{
  border: 1px solid #57a329;
  background-color: #fff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 49%);
}
.airh4{
  color: #fff;
}
.airlists ul{
  padding-left: 0px !important;
    list-style-type: none;
    color: #fff;
    margin-top: 20px;
}
.light_theme .airlists ul{
    color: black;
}
.airlists ul li p:nth-child(1){
  color: #ffff;
}
.light_theme .airlists ul li p:nth-child(1){
  color: black;
  font-weight: 500;
}
.airlists ul li {
border-bottom: 1px solid #80808047;
margin-top: 14px;
}
.airlists ul li p{
  margin-bottom: 0px !important;
    padding-bottom: 8px;
}
.airlists ul li a{
  color: #31d0aa;
  word-break: break-word;
}
.light_theme .airlists ul li a{
  font-weight: 500;
}
.airlists ul li a:hover{
  color: #31d0aa;
}
.light_theme .no_data_text
{
  color:#000 !important;
}

.badge_trend
{
  position: absolute !important;
    top: -4px;
    right: 0px;
    padding-top: 9px !important;
    background-image: linear-gradient(101deg, #31d0aa 0%, #31d0aa 100%) !important;
}
.badge_trend_detail
{ position: absolute !important;
  top: -15px;
  right: -3px;
  padding-top: 6px !important;
  background-image: linear-gradient(101deg, #31d0aa 0%, #31d0aa 100%) !important;

}
.badge-kyc-rect.badge_trend
{
  padding-top: 9px !important;

}
.badge-kyc-rect.badge_trend_detail
{
  padding-top: 6px !important;

}

.conta_toppols .badge-kyc-rect.badge_trend
{
padding-right: 15px !important;
}

.loading.blk_lkevl_stripe
{
  display: block !important;
}

.stripe {
  min-height: 10px;
  background-color: #babbbc;
  display: inline-block;
}

.small-stripe {
  width: 40%;
}

.medium-stripe {
  width: 70%;
}

.long-stripe {
  width: 100%;
}
.loading 
{
  min-width: 100px;
  display: inline;
}
 .loading .stripe {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

@keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes hintloading
{
  0% {
    opacity: 0.5;
  }
  50%  {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.inside_span_a,
.inside_span_a:hover,.inside_span_a:focus
{
  color: #fff;
}


.light_theme .inside_span_a
{
  color: #1f0c35;
}
.light_theme .inside_span_a:hover,.light_theme .inside_span_a:focus
{
  color: #fff;
}
.text_in_badge_a
{
  color:#000 !important;
}
.badge-green-rect .text_in_badge_a
{
  color:#000 !important;

}

.flex_badges_dv
{
  display: flex;
  align-items: center;
  min-width: 200px;
}

@media only screen and (max-width:575px)
{
  .mobile_btn_div_flex
  {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
  .mobile_btn_div_flex .span_hide_mob
  {
    display: none;
  }
  .flex_mob_blk_div.d-flex
  {
    display: block !important;
  }
}

@media only screen and (min-width:768px) and (max-width:1400px)
{
  .flex_mob_blk_div.align_col_bkk
  {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .flex_mob_blk_div.align_col_bkk .flex_badges_dv
  {
    margin-bottom: 10px !important;
  }
}

.img_filter_white
{
  filter: brightness(0) invert(1);
  max-width: 20px !important;
}

.mobile_btn_div_flex
{
  display: flex;
  align-items: center;
  margin-right: 5px;
}


.light_theme .nav-menu .get-started-btn
{
color:#000 !important;
}

.light_theme .nav-menu .get-started-btn svg
{
  fill: #000 !important;
}


/* .light_theme .nav-menu .get-started-btn .img_filter_white
{
  filter: unset !important;
} */

.light_theme .nav-menu .get-started-btn:hover
{
  color: #31d0aa !important;
  background: transparent !important;
}
.light_theme .nav-menu .get-started-btn:hover svg
{
  fill: #000 !important;

}

.light_theme .nav-menu .get-started-btn:hover .img_filter_white
{
  filter: brightness(0) invert(0) !important;

}
.lis_paren_side_cry
{
  color:#fff;
  padding: 20px 0px;
}
.lis_paren_side_cry img
{
  margin-right: 5px;
}
#sidebar .lis_paren_side_cry span
{
  display: none;
}
#sidebar .lis_paren_side_cry
{
  margin-left: 10px;
}
#sidebar.side_shrink .lis_paren_side_cry span
{
display: block;
}
#sidebar.side_shrink .lis_paren_side_cry
{
  margin-left: 0px !important;
}
.lis_paren_side_cry
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.allocation{
  border-bottom: 1px solid #3f3f3f;
  padding-bottom: 8px;
}
.light_theme .allocation {
  border-bottom: 1px solid #3f3f3f57;
}
.tabless .pagination{
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.tabless .table-bordered {
    border: 1px solid #101010;
}
.light_theme .tabless .table-bordered {
  border: 1px solid #ffffff;
}
.tabless thead{
  display: none;
}
.tabless .table tr:first-child {
  border-bottom: 1px solid #272727;
}
.tabless .table tr {
  background: #101010 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border-bottom: 1px solid #272727;
}
.tabless .table tr td:nth-child(2) {
 text-align: end;
}
.tabless .table tr td a {
  color: #31d0aa;
  font-size: 13px;
  /* font-weight: 500; */
 }

 .tabless .table tr td a:hover {
  color: #31d0aa;
}
 .tabless .page-item.active .page-link {
  color: #fff;
  background-color: #31d0aa;
  border-color: #31d0aa;
}
.tabless .page-item.disabled .page-link {
  color: #fff;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #31d0aa;
  font-weight: 500;
}
.light_theme .tabless .page-item.disabled .page-link {
  color: black;
}
.tabless .page-link {
  color: #ffff;
  background-color: transparent;
  border: 1px solid #31d0aa;
}
.light_theme .tabless .page-link {
  color: black;
}
.tabless .page-link:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #31d0aa;
  border-color: #31d0aa;
}
.tabless #pageDropDown,.react-bootstrap-table-pagination-total{
  display: none;
}


@media only screen and (min-width:992px)
{
  #sidebar.side_shrink .lis_paren_side_cry span
{
display: none;
}
#sidebar.side_shrink .lis_paren_side_cry
{
  margin-left: 10px !important;
}
#sidebar .lis_paren_side_cry span
{
  display: block;
}
#sidebar .lis_paren_side_cry 
{
  margin-left: 0px !important;
}
}


@media only screen and (max-width:575px)
{
.tabless .pagination{
   font-size: 9px;
}
.listed .d-flex{
  display: unset !important;
}
.listed ul li {
  border-bottom: 0px solid #80808047;
  margin-top: 14px;
}
.tabless .table td, .table th {
  padding: 0.45rem;
}
}
#sidebar .lis_paren_side_cry span
{
  font-size: 13px !important;
}

.light_theme .lis_paren_side_cry
{
  color:#000;
}
.light_theme .lis_paren_side_cry img
{
  filter: brightness(0) invert(0);
}


.text-success
{
  color:#368673 !important;
}

.headeing_tet_flex img
{
  max-width: 30px;
    border-radius: 50%;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    bordeR: 1px solid #ccc;
}

.csv_modal_green_text
{
  color:#31d0aa !important;

}
.csv_modal_green_text:hover
{
  color:#fff !important;

}

.light_theme .csv_modal_green_text:hover
{
  color:#000 !important;

}

.parent_1_grad:hover .lis_paren_side
{
  background: transparent linear-gradient(101deg, #31d0aa 0%, #31d0aa 100%) 0% 0% no-repeat padding-box;
  color: #000 !important;
  border-radius: 0px 10px 10px 0px;
}
.nodata_tet
{
  color:#fff;
}
.light_theme .nodata_tet
{
  color:#000;
}
.inut_div_vi_sc
{
  color: #d6d6d6 !important;
    font-size: 12px !important;
    background: #393939 0% 0% no-repeat padding-box;
    border: none !important;
    padding: 20px 10px !important;
}
.inut_div_vi_sc p
{
  color: #d6d6d6 !important;
margin-bottom: 2px !important;
font-size: 12px !important;
white-space: break-spaces;
    word-break: break-all;

}

.light_theme .nav_green_scroll_tab .nav-link.active
{
background: transparent !important;
}